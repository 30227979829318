/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        console.log('%cA Rebuild Group Website', 'color:666;');
        console.log('%chttps://rebuild.group', 'color:orange;');

        /** Mobile NAV */
        $('.navbar-toggler').click(function() {
          // Calling a function in case you want to expand upon this.
          toggleNav();
        });

        function toggleNav() {
          if ($('#main-wrapper').hasClass('show-nav')) {
            // Do things on Nav Close
            $('#main-wrapper').removeClass('show-nav');
          } else {
            // Do things on Nav Open
            window.scrollTo(0, 0);
            $('#main-wrapper').addClass('show-nav');
          }
        }

        /** Footer Animation */
        var cityVideo = document.getElementById('cityVideo');
        var controller = new ScrollMagic.Controller();

        var scene = new ScrollMagic.Scene({
          triggerElement: "#cityVideo", 
          duration: 450
        })
        .addTo(controller)
        //.addIndicators()

        .on("enter", function() {
          cityVideo.play();
        })
        .on("leave", function() {
          cityVideo.pause();
        });


        /** Feature Story */
        // set animation timing
        var animationDelay = 4500;

        initHeadline();

        function initHeadline() {
          // initialise headline animation
          animateHeadline($('.fs-heading-wrap'));
        }

        function animateHeadline($headlines) {
          var duration = animationDelay;
          $headlines.each(function() {
            var headline = $(this);
            var spanWrapper = headline.find('.marker');
                //newWidth = spanWrapper.width() + 5;
            //spanWrapper.css('width', newWidth);

            //trigger animation
            setTimeout(function(){ hideWord( headline.find('.is-visible').eq(0) ) }, duration);
          });
        }

        function hideWord($word) {
          var nextWord = takeNext($word);
          switchWord($word, nextWord);
          slideNext();
          setTimeout(function(){ hideWord(nextWord) }, animationDelay);
        }

        function takeNext($word) {
          return (!$word.is(':last-child')) ? $word.next() : $word.parent().children().eq(0);
        }
      
        function takePrev($word) {
          return (!$word.is(':first-child')) ? $word.prev() : $word.parent().children().last();
        }
      
        function switchWord($oldWord, $newWord) {
          $oldWord.removeClass('is-visible').addClass('is-hidden');
          $newWord.removeClass('is-hidden').addClass('is-visible');
        }

        /** Image Slider */
        var inWrap = $('.inner-wrapper'),
            $slide = $('.slide');

        function slideNext() {
          inWrap.animate({left: '-200%'}, 200, function() {
            inWrap.css('left', '-100%');
            $('.slide').last().after($('.slide').first());
          });
        }
                
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'getting_an_sba_loan': {
      init: function() {
        // JavaScript to be fired on the about us page

        if (window.location.search.indexOf('type=7a') > -1) {
          $('#home-tab').tab('show'); //show 7a
        }
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
